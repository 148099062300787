import PropTypes from 'prop-types'
import {Redirect} from "react-router-dom"
import React, { Component } from 'react'
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'

import { StateContext } from './state.jsx';

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as='h1'
      content='Photon'
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    />
    <Header
      as='h2'
      content='Lightning-fast scheduling.'
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  static contextType = StateContext;
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const [{ firebaseUser }, dispatch] = this.context;
    const { children } = this.props
    const { fixed } = this.state

    if (firebaseUser) {
      return (
        <Redirect to={'/cal'}/>
      )
    }

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 700, padding: '1em 0em' }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item as='a' active>
                  Home
                </Menu.Item>
                <Menu.Item position='right'>
                  <Button as='a' href="#sign-in" inverted={!fixed}>
                    Log in
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as='a' active>
            Home
          </Menu.Item>
          <Menu.Item as='a'>Log in</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 350, padding: '1em 0em' }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                  <Button as='a' inverted>
                    Log in
                  </Button>
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const HomepageLayout = () => (
  <ResponsiveContainer>
    {/* <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column>
            <p style={{ fontSize: '1.33em', textAlign: 'center' }}>
              Photon is still in beta.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment> */}
    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid inverted stackable verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column width={5}>
            </Grid.Column>
            <Grid.Column width={3} textAlign='center' >
              <p>
                Photon is currently in beta.
              </p>
            </Grid.Column>
            <Grid.Column width={3} textAlign='center'>
              <a href='mailto:hello@usephoton.com' style={{ color: '#B9B9B9' }}>Contact Us</a>
            </Grid.Column>
            <Grid.Column width={5}>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
)

export default HomepageLayout;



    // <Segment style={{ padding: '8em 0em' }} vertical>
    //   <Container text>
    //     <Header as='h3' style={{ fontSize: '2em' }}>
    //       Breaking The Grid, Grabs Your Attention
    //     </Header>
    //     <p style={{ fontSize: '1.33em' }}>
    //       Instead of focusing on content creation and hard work, we have learned how to master the
    //       art of doing nothing by providing massive amounts of whitespace and generic content that
    //       can seem massive, monolithic and worth your attention.
    //     </p>
    //     <Button as='a' size='large'>
    //       Read More
    //     </Button>
    //     <Divider
    //       as='h4'
    //       className='header'
    //       horizontal
    //       style={{ margin: '3em 0em', textTransform: 'uppercase' }}
    //     >
    //       <a href='#'>Case Studies</a>
    //     </Divider>
    //     <Header as='h3' style={{ fontSize: '2em' }}>
    //       Did We Tell You About Our Bananas?
    //     </Header>
    //     <p style={{ fontSize: '1.33em' }}>
    //       Yes I know you probably disregarded the earlier boasts as non-sequitur filler content, but
    //       it's really true. It took years of gene splicing and combinatory DNA research, but our
    //       bananas can really dance.
    //     </p>
    //     <Button as='a' size='large'>
    //       I'm Still Quite Interested
    //     </Button>
    //   </Container>
    // </Segment>

    // <Segment style={{ padding: '8em 0em' }} vertical>
    //   <Container text>
    //     <Header as='h3' style={{ fontSize: '2em' }}>
    //       Breaking The Grid, Grabs Your Attention
    //     </Header>
    //     <p style={{ fontSize: '1.33em' }}>
    //       Instead of focusing on content creation and hard work, we have learned how to master the
    //       art of doing nothing by providing massive amounts of whitespace and generic content that
    //       can seem massive, monolithic and worth your attention.
    //     </p>
    //     <Button as='a' size='large'>
    //       Read More
    //     </Button>
    //     <Divider
    //       as='h4'
    //       className='header'
    //       horizontal
    //       style={{ margin: '3em 0em', textTransform: 'uppercase' }}
    //     >
    //       <a href='#'>Case Studies</a>
    //     </Divider>
    //     <Header as='h3' style={{ fontSize: '2em' }}>
    //       Did We Tell You About Our Bananas?
    //     </Header>
    //     <p style={{ fontSize: '1.33em' }}>
    //       Yes I know you probably disregarded the earlier boasts as non-sequitur filler content, but
    //       it's really true. It took years of gene splicing and combinatory DNA research, but our
    //       bananas can really dance.
    //     </p>
    //     <Button as='a' size='large'>
    //       I'm Still Quite Interested
    //     </Button>
    //   </Container>
    // </Segment>



