import React from "react";
import {
  Message,
  Dimmer,
  Loader,
  Button,
  Modal,
  Card,
  Menu,
  Icon,
  Label,
  Header,
  Image
} from "semantic-ui-react";

import firebase from 'firebase';
import axios from 'axios';

// Get our global state object
import { StateContext } from '../state.jsx';

const photonConfig = (process.env.NODE_ENV === 'production') ? require('../../config.json').photon.production : require('../../config.json').photon.development;

class AccessRequests extends React.Component{
  static contextType = StateContext;
  static defaultProps = {}

  state = {
    error: undefined,
    isLoading: false,
    isLoadingId: undefined,
    modalOpen: false
  }

  componentDidMount = () => {
    this.refreshAccessRequests();
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  refreshAccessRequests = () => {
    const [{ firebaseUserIdToken}, dispatch] = this.context;
    axios({
      method: 'get',
      url: `${photonConfig.functionsURL}accessRequest`,
      headers: { Authorization: `Bearer ${firebaseUserIdToken}` }
    })
    .then(result => {
      dispatch({
        type: 'updateAccessRequests',
        newAccessRequests: result.data
      });
      this.setState({isLoading: false});
    })
    .catch(error => {
      console.log(error);
      this.setState({isLoading: false});
    });
  }

  handleGrantAccess = (id, permission, e) => {
    this.setState({isLoading: true, isLoadingId: id});
    const [{ firebaseUserIdToken}, dispatch] = this.context;
    axios({
      method: 'post',
      url: `${photonConfig.functionsURL}grantAccess`,
      headers: { Authorization: `Bearer ${firebaseUserIdToken}` },
      data: {accessRequestId: id, permission: permission}
    })
    .then(result => {
      this.refreshAccessRequests();
    })
    .catch(error => {
      console.log(error.response);
      this.setState({error: error.response.data.message, isLoading: false})
    });
  }

  render() {
    const [{ accessRequests }, dispatch] = this.context;
    let {error} = this.state;

    return (
      <Modal trigger={
          <Menu.Item as='a' onClick={this.handleOpen} >
          <Icon name='tasks' />
          <Label color={accessRequests.length > 0 ? 'red' : 'grey'}>
            {accessRequests.length}
          </Label>
        </Menu.Item>} 
      size='small' 
      open={this.state.modalOpen}
      onClose={this.handleClose}
      centered={false}
      >
        <Modal.Header>Pending Access Requests</Modal.Header>
        <Modal.Content >
          { this.state.error &&
            <Message negative>
              <Message.Header>Oops! Looks like something broke. </Message.Header>
              <p>{this.state.error}</p>
            </Message>
          }
          <Card.Group>
            {
              accessRequests.map((value, index) => {
                return (
                  <Card fluid key={index}>
                    <Dimmer active={(this.state.isLoading && (this.state.isLoadingId == value.id))} >
                      <Loader />
                    </Dimmer>      
                    <Card.Content>
                      <Image
                        floated='right'
                        avatar
                        src={value.senderAccountDetails.picture}
                      />
                      <Card.Header>{value.senderAccountDetails.name}</Card.Header>
                      <Card.Meta>{value.senderAccountDetails.email}</Card.Meta>
                      <Card.Description>
                        {value.senderAccountDetails.name} wants to access your calendar on Photon.
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <div className='ui four buttons'>
                        <Button basic size='mini' color='green' onClick={(e) => this.handleGrantAccess(value.id, 'reader', e) }>
                          Approve
                          {/* Approve All Details */}
                        </Button>
                        {/* <Button basic size='mini' color='green' onClick={(e) => this.handleGrantAccess(value.id, 'freeBusyReader', e) }>
                          Approve Free/Busy
                        </Button> */}
                        <Button basic size='mini' color='red' onClick={(e) => this.handleGrantAccess(value.id, 'reject', e) }>
                          Decline
                        </Button>
                      </div>
                    </Card.Content>
                  </Card>
                )
              })
            }
           </Card.Group>

        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={this.handleClose}>Close</Button>
        </Modal.Actions>

      </Modal>
    );
  }
}

export default AccessRequests;
