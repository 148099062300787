/* global gapi */
import React from 'react';
import {HashRouter as Router, Route, Redirect, withRouter} from 'react-router-dom'
import qs from 'qs';
import { Container } from 'semantic-ui-react'
import firebase from 'firebase';

// Import components
import Calendar from './main/calendar.jsx';
import Login from './main/login.jsx';
import HomepageLayout from './main/home.jsx';
import ManageAccessRequest from './main/manageAccessRequest.jsx';
import PrivacyPolicy from './main/privacy/privacypolicy.jsx';
import TermsAndConditions from './main/privacy/terms.jsx';
import CookiePolicy from './main/privacy/cookie.jsx';
import AuthWrapper from './main/AuthWrapper.jsx';
import Admin from './main/admin.jsx';
import * as FullStory from '@fullstory/browser';

// Import the state provider for the whole app
import { StateProvider } from './main/state.jsx';

var firebaseConfig = require('./config.json').firebase;
const photonConfig = (process.env.NODE_ENV === 'production') ? require('./config.json').photon.production : require('./config.json').photon.development;

// Initialize Firebase & Google API
const firebaseApp = firebase.initializeApp(firebaseConfig);
if (process.env.NODE_ENV === 'production') {
  firebase.analytics();
}
const firebaseDatabase = firebaseApp.firestore();

// Initialize Fullstory
// let inProduction = process.env.NODE_ENV === 'production';
FullStory.init({ orgId: 'SCRXB'});

// For all authenticated users
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    firebase.auth().currentUser != null
      ? <Component {...props} {...rest} />
      : <Redirect to={`/sign-in?redirect=${encodeURIComponent(window.location.href)}`} />
  )} />
)

class Logout extends React.Component{
  render() {
    console.log("Logging out")
    firebase.auth().signOut().then(() => {
      console.log('User signed out of firebase.');
    }).catch(function(error) {
      console.log(error)
    });

    return (
      <Redirect to={'/'}/>  
    );
  }
}

// Only for Photon admins
const AdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    firebase.auth().currentUser && photonConfig.adminEmails.includes(firebase.auth().currentUser.email)
      ? <Component {...props} {...rest} />
      : <Redirect to={'/sign-in'} />
  )} />
)

const initialState = {
  theme: { primary: 'green' },
  firebaseApp: firebaseApp,
  firebaseUser: undefined,
  firebaseUserIdToken: undefined,
  currentTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  firebaseUser: null,
  calendarMap: {},
  isLoading: true,
  accessRequests: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'changeTheme':
      return {
        ...state,
        theme: action.newTheme
      };
    case 'changeCurrentTimezone': 
      return {
        ...state,
        currentTimezone: action.newTimezone
      };      
    case 'changeFirebaseUser': 
      return {
        ...state,
        firebaseUser: action.newFirebaseUserAndToken.user,
        firebaseUserIdToken: action.newFirebaseUserAndToken.idToken
      };      
    case 'updateCalendarMap': 
      return {
        ...state,
        calendarMap: action.newCalendarMap
      };
    case 'toggleIsLoading': 
      return {
        ...state,
        isLoading: !state.isLoading
      };      
    case 'toggleCalendarVisibility':
      let newCalendarMap = state.calendarMap;
      newCalendarMap[action.calendarId]['visible'] = !newCalendarMap[action.calendarId]['visible'];
      return {
        ...state,
        calendarMap: newCalendarMap
      };
    case 'updateAccessRequests': 
      return {
        ...state,
        accessRequests: action.newAccessRequests
      };      
    default:
      return state;
  }
};

class App extends React.Component{

  componentDidUpdate(prevProps, prevState) {
    Object.entries(this.props).forEach(([key, val]) =>
      prevProps[key] !== val && console.log(`App Prop '${key}' changed`)
    );
    if (this.state) {
      Object.entries(this.state).forEach(([key, val]) =>
        prevState[key] !== val && console.log(`App State '${key}' changed`)
      );
    }
  }    

  render() {
    console.log("rendering app.jsx")
    return (
    <StateProvider initialState={initialState} reducer={reducer}>
        <AuthWrapper/>
        <Router>
          <Container fluid >
            <Route path="/privacypolicy" component={PrivacyPolicy}/>
            <Route path="/termsandconditions" component={TermsAndConditions}/>
            <Route path="/cookiepolicy" component={CookiePolicy}/>
            <Route exact path="/" component={HomepageLayout}/>
            <Route path="/sign-in" component={Login} />
            <Route path="/logout" component={Logout} />
            <PrivateRoute path="/manage-access-request/:accessRequestId" component={ManageAccessRequest} />
            <PrivateRoute path="/cal" component={Calendar} firebaseDatabase={firebaseDatabase} style={{height:'100%'}} />
            <AdminRoute path="/admin" component={Admin} />
          </Container>
        </Router>
    </StateProvider>      

    );
  }
}

export default App;



// var googleConfig = require('./config.json').google;
  // initClient = () => {
  //   gapi.client.init({
  //     apiKey: googleConfig.apiKey,
  //     clientId: googleConfig.clientId,
  //     discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
  //     scope: googleConfig.scopes.join(' ')
  //   }).then(() => {
  //     // Listen for sign-in state changes.
  //     gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);

  //     let isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
  //     console.log('Initialized gapi and user is signed in? ' + isSignedIn);
  //     this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());

  //   }, function(error) {
  //     console.log(JSON.stringify(error, null, 2));
  //   });
  // }
