import React from "react";
import OauthPopup from "react-oauth-popup";
import {
	List,
	Modal,
	Dropdown,
	Image,
	Button,
	Header,
	Segment
} from "semantic-ui-react";

// Get our global state object
import { StateContext } from './state.jsx';

const photonConfig = (process.env.NODE_ENV === 'production') ? require('../config.json').photon.production : require('../config.json').photon.development;

class Accounts extends React.Component {
  	static contextType = StateContext;	
	static defaultProps = {};

	state = {modalOpen: false};

	componentDidMount = () => {};

	onMicrosoftCode = (code, params) => {
		console.log("wooooo a code", code);
		this.props.addNewAccountWithAuthCode(code, 'MICROSOFT');
	};
	onGoogleCode = (code, params) => {
		console.log("wooooo a code", code);
		this.props.addNewAccountWithAuthCode(code, 'GOOGLE');
	};

	handleOpen = () => this.setState({ modalOpen: true })

	handleClose = () => this.setState({ modalOpen: false })

	onClose = () => {
		console.log("closed!");
	};

	render() {
    	const [{ firebaseUser }, dispatch] = this.context;
		// console.log("rendering accounts");
		return (
		<Modal 
			size='tiny' 
			trigger={<Button compact primary onClick={this.handleOpen} >Link your calendar</Button>} 
			centered={false}
			open={this.state.modalOpen}
			onClose={this.handleClose} >
			<Modal.Header>Manage Accounts</Modal.Header>
			<Modal.Content image>
				<Modal.Description>
					<Header>Connected Accounts</Header>
					<List relaxed verticalAlign="middle" >
					{this.props.accounts.map((value, index) => {
						return (
						<List.Item key={value.id}>
							<List.Content floated="right">
								<Dropdown inline text="" icon="ellipsis vertical" style={{ marginTop: "0.5em" }}>
									<Dropdown.Menu>
										<Dropdown.Item icon="refresh" text="Pull events" />
										<Dropdown.Item icon="trash" text="Remove" onClick={e => this.props.handleRemoveAccount(value.id, e)}/>
									</Dropdown.Menu>
								</Dropdown>
							</List.Content>
							<Image avatar src={ value.provider === "MICROSOFT" ? require("../img/msft.png") : require("../img/google.png")}/>
							<List.Content>
								<b>{value.provider === "MICROSOFT" ? "Microsoft" : "Google"}</b>
							</List.Content>
						</List.Item>									
						);
					})}
					</List>
					<Segment.Inline>
						<OauthPopup
							url={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?scope=offline_access%20calendars.read&response_type=code&client_id=84e3c750-f068-4f56-952a-6f7c001204db&redirect_uri=${photonConfig.oAuthRedirectURL}&state=msft`}
							onCode={this.onMicrosoftCode}
							onClose={this.onClose}
						>
							<Button
								fluid
								icon="microsoft"
								primary
								content="Connect Microsoft"
								style={{ marginBottom: "1em" }}
							/>
						</OauthPopup>
						<OauthPopup
							url={`https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events&response_type=code&access_type=offline&prompt=consent&client_id=383666567664-k9g2pahsho6g2gn7u0arbb4uj78lfhc4.apps.googleusercontent.com&redirect_uri=${photonConfig.oAuthRedirectURL}&state=google`}
							onCode={this.onGoogleCode}
							onClose={this.onClose}
						>
							<Button
								fluid
								icon="google"
								primary
								content="Connect Google"
							/>
						</OauthPopup>
					</Segment.Inline>

				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button color='black' onClick={this.close}>Close</Button>
			</Modal.Actions>						
		</Modal>			
		);
	}
}



export default Accounts;