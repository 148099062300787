import React from 'react';
import {Redirect, withRouter} from 'react-router-dom'
import qs from 'qs'
import { Grid, Image, Container, Button, Header, Icon, Form, Loader, Dimmer, Segment, Message, Responsive } from 'semantic-ui-react'
import GoogleButton from 'react-google-button';
import SignInButton from '@dougrich/react-signin-microsoft/dist/dark';
import firebase from 'firebase';
import OauthPopup from "react-oauth-popup";
import axios from 'axios';
import * as FullStory from '@fullstory/browser';

// Get our global state object
import { StateContext } from './state.jsx';

const photonConfig = (process.env.NODE_ENV === 'production') ? require('../config.json').photon.production : require('../config.json').photon.development;


class Login extends React.Component {
  static contextType = StateContext;

  state = {
    invite: null,
    step: 0,
    provider: undefined
  }

  componentDidMount = () => {
    const [{firebaseUserIdToken, firebaseUser}, dispatch] = this.context;

    var params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if ('inviteId' in params) {
      // Beta only
      axios({
        method: 'get',
        url: `${photonConfig.functionsURL}invite/${params.inviteId}`
      }).then(result => {
        this.setState({'invite': result.data})
        console.log(result.data);
      }).catch(error => {
        console.log('Error', error);
      });
    }
  }

  handleOutlookSignin = () => {
    var provider = new firebase.auth.OAuthProvider('microsoft.com');
    firebase.auth().signInWithPopup(provider).then((result) => {
      console.log(result);
      if (result.additionalUserInfo.isNewUser) {
        this.setState({step:1, provider: 'MICROSOFT'});
      } else {
        this.setState({step:2});
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  handleGoogleSignin = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).then((result) => {
      console.log(result);
      if (result.additionalUserInfo.isNewUser) {
        var invite = {}; // Adding empty invite here (and next few lines) to enable non-invite sign-ups
        if (this.state.invite != null) {
          invite = this.state.invite;
        }
        this.setState({step:1, provider: 'GOOGLE', invite: invite});
      } else {
        this.setState({step:2});
      }

    }).catch((error) => {
      console.log(error)
    });
  }

  onMicrosoftCode = (code, params) => {
    console.log("wooooo a code", code);
    this.addNewAccountWithAuthCodeAndRefreshToken(code, 'MICROSOFT');
  };

  onGoogleCode = (code, params) => {
    console.log("wooooo a code", code);
    this.addNewAccountWithAuthCodeAndRefreshToken(code, 'GOOGLE');
  };

  addNewAccountWithAuthCodeAndRefreshToken = (authorizationCode, provider) => {
    const [{firebaseUserIdToken, firebaseUser}, dispatch] = this.context;

    axios({
      method: 'post',
      url: `${photonConfig.functionsURL}token`,
      data: {
        'code': authorizationCode,
        'tenant': 'common',
        'provider': provider
      }
    })
    .then(tokenResult => {
      console.log(tokenResult);

      // Build Firebase credential with the Google ID token.
      let idToken = tokenResult.data.id_token;
      let refreshToken = tokenResult.data.refresh_token;
      let scope = tokenResult.data.scope;
      let credential = '';

      // Create the user's linked account w/ refresh token
      axios({
        method: 'post',
        url: `${photonConfig.functionsURL}linkedAccounts`,
        headers: { Authorization: `Bearer ${firebaseUserIdToken}` },
        data: {
          provider: provider,
          refresh_token: refreshToken,
          id_token: idToken,
          scope: scope
        }
      })
      .then(result => {
        console.log(result);
        this.setState({step: 2});
      })
      .catch(error => {
        console.log(error);
      });
    })
    .catch(error => {
      console.log(error);
    });

  }

  render() {
    const [{firebaseUser}, dispatch] = this.context;
    var params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    // If a user is logged in and comes with ?redirect=, redirect them to that url (enables e.g. managing access requests)
    if (firebaseUser) {
      if ('redirect' in params) {
        // Redirect using window.location so we can use an absolute URL
        window.location = decodeURIComponent(params.redirect);
        return (<div></div>);
      }
    }    

    console.log("rendering login.jsx")
    let {step, provider} = this.state;

    let googleOAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events openid email profile&response_type=code&access_type=offline&prompt=consent&client_id=383666567664-k9g2pahsho6g2gn7u0arbb4uj78lfhc4.apps.googleusercontent.com&redirect_uri=${photonConfig.oAuthRedirectURL}&state=google`;
    let microsoftOAuthUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?scope=offline_access%20calendars.read%20openid%20email%20profile&response_type=code&client_id=84e3c750-f068-4f56-952a-6f7c001204db&redirect_uri=${photonConfig.oAuthRedirectURL}&state=msft`;

    var userIsAcceptingInvite = false;
    if (this.state.invite != null) {
      userIsAcceptingInvite = true;
    }

    let sidebarContent = <div></div>;
    if (!userIsAcceptingInvite && this.state.step === 0) {
      // If the user is signed in, just send them to  cal, otherwise, show the sign-in form.
      // We check to see if step == 0 bc if the user accidentally signs-in instead of register, we still want to take them through the flow

      if (firebaseUser) {
        return (
          <Redirect to={'/cal'}/>
        )
      }

      sidebarContent = (
        <Segment>
          <Image style={{height:'3em', marginBottom: '1.5em'}} src={require("../img/photonlogo_wide.svg")} />
          <Header as='h2' color='blue' style={{marginBottom:'1em', fontWeight:200}}>Welcome to Photon</Header>
          <Button basic icon fluid labelPosition='left' onClick={this.handleGoogleSignin}><Icon name='google' />Sign in with  Google</Button>
          <br></br>
          <Button basic icon fluid labelPosition='left' onClick={this.handleOutlookSignin}><Icon name='microsoft' />Sign in with Microsoft</Button>
        </Segment>
      );

    } else {
      // The user is in the registration flow, so check what step they are on
      if (step === 0) {
        sidebarContent = (
          <>
          <Segment attached='top'>
            <Image style={{height:'3em', marginBottom: '1.5em'}} src={require("../img/photonlogo_wide.svg")} />

            <Message color='olive'>{this.state.invite.senderAccountDetails.name} has invited you to Photon!</Message>

            <Header as='h2' color='blue' style={{marginBottom:'1em', fontWeight:200}}>Create a Photon Account</Header>
            <p style={{marginBottom:'2em'}}>Choose how you'd like to sign-in to Photon. You will grant access to your calendar in the next step. </p>
            <Button basic icon fluid labelPosition='left' onClick={this.handleGoogleSignin}><Icon name='google' />Sign up with Google</Button>
            <p style={{marginTop: '0.5em', marginBottom: '1.5em', fontSize: '0.8em'}}>Gmail and G Suite Accounts</p>
            <Button basic icon fluid labelPosition='left' onClick={this.handleOutlookSignin}><Icon name='microsoft' />Sign up with Microsoft</Button>
            <p style={{marginTop: '0.5em', fontSize: '0.8em'}}>Outlook.com and Office365 Accounts</p>
          </Segment>
          <Message attached='bottom' size='tiny'>
            By creating an account, you agree to our <a as='a' href="#termsandconditions"> terms of service </a> and <a as='a' href="#privacypolicy"> privacy policy</a>. We’ll occasionally send account-related emails.
          </Message>
          </>
        )
      } else if (step === 1) {
        let oauthButton = (<></>);
        if (provider === 'GOOGLE'){
          oauthButton = (
            <OauthPopup url={googleOAuthUrl} onCode={this.onGoogleCode} onClose={this.onClose}>
            <Button basic icon fluid labelPosition='left'><Icon name='google' />Grant access to Google</Button>
            </OauthPopup>
          );
        } else if (provider === 'MICROSOFT') {
          oauthButton = (
            <OauthPopup url={microsoftOAuthUrl} onCode={this.onMicrosoftCode} onClose={this.onClose}>
            <Button basic icon fluid labelPosition='left'><Icon name='microsoft' />Grant access to Microsoft</Button>
            </OauthPopup>
          );
        }
            // <OauthPopup url={googleOAuthUrl} onCode={this.onGoogleCode} onClose={this.onClose}>
            //   <GoogleButton type="light" label={'Sign in with Google'}  />
            // </OauthPopup>
            // <OauthPopup url={microsoftOAuthUrl} onCode={this.onMicrosoftCode} onClose={this.onClose}>
            //   <SignInButton style={{width:'100%'}} />
            // </OauthPopup>


        sidebarContent = (
          <Segment >
            <Image style={{height:'3em', marginBottom: '1.5em'}} src={require("../img/photonlogo_wide.svg")} />
            <Header as='h2' color='blue' style={{marginBottom:'1em', fontWeight:200}}>Grant access</Header>
            <p style={{marginBottom:'2em'}}>Now that you have a Photon Account, clicking below will ask for permission to access for calendars. </p>
            {oauthButton}
          </Segment>
        );
      } else if (step === 2 && firebaseUser) {
        return (
          <Redirect to={'/cal'}/>
        )
      }
    }

    return (

      <Grid verticalAlign='middle' container centered columns={1} textAlign='center' style={{height: '100vh'}}>
        <Grid.Column width={6} only='computer'>
          {sidebarContent}
        </Grid.Column>
        <Grid.Column width={16} only='tablet mobile'>
          {sidebarContent}
        </Grid.Column>
      </Grid>
    )
  }
}




export default withRouter(Login);
