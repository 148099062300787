import React from 'react';
import {Redirect, withRouter} from 'react-router-dom'
import { Grid, Image, Container, Button, Header, Form, Segment, Message, Responsive, Card, Dimmer, Loader } from 'semantic-ui-react'
import GoogleButton from 'react-google-button';
import SignInButton from '@dougrich/react-signin-microsoft/dist/dark';
import firebase from 'firebase';
import OauthPopup from "react-oauth-popup";
import axios from 'axios';

// Get our global state object
import { StateContext } from './state.jsx';

const photonConfig = (process.env.NODE_ENV === 'production') ? require('../config.json').photon.production : require('../config.json').photon.development;


class ManageAccessRequest extends React.Component {
  static contextType = StateContext;

  state = {
    error: undefined,
    isLoading: false,
    isLoadingId: undefined,
    rejected: false
  }

  componentDidMount = () => {
    const [{firebaseUserIdToken, firebaseUser}, dispatch] = this.context;

    axios({
      method: 'get',
      url: `${photonConfig.functionsURL}accessRequest/${this.props.match.params.accessRequestId}`,
      headers: { Authorization: `Bearer ${firebaseUserIdToken}` }
    }).then(result => {
      this.setState({'accessRequest': result.data})
      console.log(result.data);
    }).catch(error => {
      this.setState({'error': error.response.data});
      console.log('Error', error.response);
    });
  }

  handleGrantAccess = (accessRequestId, permission, e) => {
    this.setState({isLoading: true, isLoadingId: accessRequestId});
    const [{ firebaseUserIdToken}, dispatch] = this.context;
    axios({
      method: 'post',
      url: `${photonConfig.functionsURL}grantAccess`,
      headers: { Authorization: `Bearer ${firebaseUserIdToken}` },
      data: {accessRequestId: accessRequestId, permission: permission}
    })
    .then(result => {
      if (permission != 'reject') {
        this.setState({success: `${this.state.accessRequest.senderAccountDetails.name} now has access to your calendar.`, isLoading: false});
      } else {
        this.setState({success: `You have declined the request from ${this.state.accessRequest.senderAccountDetails.name}.`, isLoading: false, rejected: true});
      }
      
    })
    .catch(error => {
      console.log(error.response);
      this.setState({error: error.response.data.message, isLoading: false});
    });

  }  

  render() {
    const [{accessRequest}, dispatch] = this.context;
    let {error} = this.state;

    var message;
    if (this.state.error) {
      message = (<Message negative>
                  <Message.Header>Small issue...</Message.Header>
                  <p>{this.state.error}</p>
                </Message>);
    } else if (this.state.success && !this.state.rejected) {
      message = (<Message positive>
                  <Message.Header>Success!</Message.Header>
                  <p>{this.state.success}</p>
                </Message>);
    } else if (this.state.success && this.state.rejected) {
      message = (<Message negative>
                  <p>{this.state.success}</p>
                </Message>);
    }
    
    var inputButtons;
    if (this.state.accessRequest && !this.state.success) {
      inputButtons = (
        <Card.Group>
          <Card fluid key={this.state.accessRequest.id}>
            <Dimmer active={(this.state.isLoading && (this.state.isLoadingId == this.state.accessRequest.id))} >
              <Loader />
            </Dimmer>      
            <Card.Content>
              <Image
                floated='right'
                avatar
                src={this.state.accessRequest.senderAccountDetails.picture}
              />
              <Card.Header>{this.state.accessRequest.senderAccountDetails.name}</Card.Header>
              <Card.Meta>{this.state.accessRequest.senderAccountDetails.email}</Card.Meta>
              <Card.Description>
                {this.state.accessRequest.senderAccountDetails.name} wants to access your calendar on Photon.
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <div className='ui four buttons'>
                <Button basic size='mini' color='green' onClick={(e) => this.handleGrantAccess(this.state.accessRequest.id, 'reader', e) }>
                  Approve
                  {/* Approve All Details */}
                </Button>
                {/* <Button basic size='mini' color='green' onClick={(e) => this.handleGrantAccess(this.state.accessRequest.id, 'freeBusyReader', e) }>
                  Approve Free/Busy
                </Button> */}
                <Button basic size='mini' color='red' onClick={(e) => this.handleGrantAccess(this.state.accessRequest.id, 'reject', e) }>
                  Decline
                </Button>
              </div>
            </Card.Content>
          </Card>
        </Card.Group>
      )
    }

    var accessRequestForm;
    accessRequestForm = (
      <div>
        {message}
        {inputButtons}
      </div>
    )
  
    return (
      <div>
        <Container>
          <Responsive as={Grid} centered minWidth={992} style={{  height: '100vh' }} verticalAlign='middle'>
            <Grid.Column width={6}>
              {accessRequestForm}
            </Grid.Column>
          </Responsive>
          <Responsive as={Grid} maxWidth={991} centered style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column width={16}>
              {accessRequestForm}
            </Grid.Column>
          </Responsive>
        </Container>
      </div>
    )
  }
}





export default withRouter(ManageAccessRequest);
