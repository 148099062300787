import React from 'react';
import {Redirect, withRouter} from 'react-router-dom'
import qs from 'qs'
import { Grid, Image, Container, Button, Header, Form, Segment, Message, Responsive } from 'semantic-ui-react'
import GoogleButton from 'react-google-button';
import SignInButton from '@dougrich/react-signin-microsoft/dist/dark';
import firebase from 'firebase';
import OauthPopup from "react-oauth-popup";
import axios from 'axios';

// Get our global state object
import { StateContext } from './state.jsx';

const photonConfig = (process.env.NODE_ENV === 'production') ? require('../config.json').photon.production : require('../config.json').photon.development;


class Admin extends React.Component {
  static contextType = StateContext;
  
  state = {
    'FORM_INVITE_recipientEmail': '',
    'FORM_INVITE_loading': false,
    'FORM_INVITE_successMessage': null,
    'FORM_INVITE_errorMessage': null
  }

  handleFormStateChange = (e, { name, value }) => this.setState({ [name]: value })

  handleSendInvite = () => {
    this.setState({FORM_INVITE_loading: true});
    const [{ firebaseUserIdToken}, dispatch] = this.context;
    const { FORM_INVITE_recipientEmail } = this.state;
    axios({
      method: 'post',
      url: `${photonConfig.functionsURL}invite`,
      headers: { Authorization: `Bearer ${firebaseUserIdToken}` },
      data: {recipientEmail: FORM_INVITE_recipientEmail, includesAccessRequest: false}
    })
    .then(result => {
      console.log(result.data);
      this.setState({FORM_INVITE_errorMessage: null,
                     FORM_INVITE_successMessage: `${this.state.FORM_INVITE_recipientEmail} has been invited. Link for user:     ${photonConfig.oAuthRedirectURL}/#/sign-in?inviteId=${result.data.id}`,
                     FORM_INVITE_loading: false});
    })
    .catch(error => {
      console.log('Invite sending error:', error);
      this.setState({FORM_INVITE_successMessage: null,
                     FORM_INVITE_errorMessage: `Error sending invite.`,
                     FORM_INVITE_loading: false});
    });
  }

  render() {
    const { FORM_INVITE_recipientEmail } = this.state;

    const inviteForm = 
      <Form onSubmit={this.handleSendInvite}
            loading={this.state.FORM_INVITE_loading}
            success={this.state.FORM_INVITE_successMessage != null}
            error={this.state.FORM_INVITE_errorMessage != null}>
        <Header as='h3'>Invite New User</Header>
        <Form.Group>
          <Form.Input
            placeholder='Email'
            value={FORM_INVITE_recipientEmail}
            name='FORM_INVITE_recipientEmail'
            onChange={this.handleFormStateChange}
          />
          <Form.Button content='Send'/>
        </Form.Group>
        <Message
          success
          header="Success"
          content={this.state.FORM_INVITE_successMessage}
        />
        <Message
          error
          header="Error"
          content={this.state.FORM_INVITE_errorMessage}
        />
      </Form>;

    return (
      <div>
        <Container>
          <Responsive as={Grid} centered minWidth={992} style={{  height: '100vh' }} verticalAlign='middle'>
            <Grid.Column width={6}>
              {inviteForm}
            </Grid.Column>
          </Responsive>
          <Responsive as={Grid} maxWidth={991} centered style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column width={16}>
              {inviteForm}
            </Grid.Column>
          </Responsive>
        </Container>
      </div>
    )
  }
}

export default withRouter(Admin);
