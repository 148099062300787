import React from "react";
import {
  Dropdown,
  Button,
  Icon,
  Container,
  Menu,
  Label,
  Modal,
  Card,
  Header,
  Image  
} from "semantic-ui-react";

import Accounts from './accounts.jsx';
import TIMEZONES from './util/timezones.json';
import firebase from 'firebase';
import axios from 'axios';
import AccessRequests from './nav/accessRequests.jsx';

// Get our global state object
import { StateContext } from './state.jsx';

const photonConfig = (process.env.NODE_ENV === 'production') ? require('../config.json').photon.production : require('../config.json').photon.development;

class Nav extends React.Component{
  static contextType = StateContext;
  static defaultProps = {}

  state = { }

  handleLogoutAction = () => {
    firebase.auth().signOut().then(() => {
      console.log('User signed out of firebase.');
    }).catch(function(error) {
      console.log(error)
    });
  }

  handleGrantAccess = () => {
    const [{ firebaseUserIdToken}, dispatch] = this.context;
    axios({
      method: 'post',
      url: `${photonConfig.functionsURL}grantAccess`,
      headers: { Authorization: `Bearer ${firebaseUserIdToken}` },
      data: {permission: 'reader'}
    })
    .then(result => {
      console.log(result);
    })
    .catch(error => {
      console.log(error);
    });    
  }

  render() {
    const [{ currentTimezone, firebaseUser, accessRequests }, dispatch] = this.context;
    // console.log("rendering nav")

    return (
      <div>
        <Menu fixed='top' borderless style={{ boxShadow: 'none' }}>
          <Container fluid >
            <Menu.Item style={{width:'387px'}} header name='/' onClick={this.handleItemClick} >
              {/*<Image style={{height: 'auto', width: '120px'}} alt='P' src={require("../img/photonlogo_wide.svg")}/>*/}
              <Image style={{height: 'auto', width: '30px'}} alt='P' src={require("../img/photonlogo_light_nomargin.svg")}/>
            </Menu.Item>
            <Menu.Item>
              <Button.Group basic compact >
                <Button onClick={this.props.calendarPrev} icon='angle left' />
                <Button onClick={this.props.calendarToday}>Today</Button>
                <Button onClick={this.props.calendarNext}  icon='angle right' />
              </Button.Group>
              <span style={{width: '1em'}}/>
              <Button.Group  basic compact >
                <Button basic compact onClick={this.props.calendarToggleWeekends}>Toggle Weekends</Button>
              </Button.Group>
            </Menu.Item>
            <Menu.Item>

            </Menu.Item>
            <Menu.Menu position='right'>
              <AccessRequests />
              <Dropdown item text={currentTimezone}>
                <Dropdown.Menu>
                  <Dropdown.Header disabled='True' icon='globe' content={'Current: '+currentTimezone} />
                  <Dropdown.Menu scrolling>
                    {Object.keys(TIMEZONES).map((zone) => (
                      <Dropdown.Item onClick={() => dispatch({
                        type: 'changeCurrentTimezone',
                        newTimezone: zone
                      })} key={zone} text={zone}  />
                    ))}
                  </Dropdown.Menu>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown item simple text={firebaseUser.email} position='right'>
                <Dropdown.Menu style={{float:'right'}}>
                  <Dropdown.Item onClick={this.handleLogoutAction}><Icon name='sign out' />  Sign Out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </Container>
        </Menu>

  </div>
    );
  }
}

export default Nav;
