import React from 'react';
// Get our global state object
import { StateContext } from './state.jsx';
// https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c
import * as FullStory from '@fullstory/browser';

class AuthWrapper extends React.Component {
  static contextType = StateContext;

  componentDidMount = () => {
    const [{firebaseApp}, dispatch] = this.context;
    firebaseApp.auth().onAuthStateChanged((user) => {  // Make sure there is a valid user object
      if (user) {
        console.log("firebase auth state changed for user: ", user.email)
        FullStory.identify(user.uid, {
         displayName: user.displayName,
         email: user.email
        });        
        user.getIdToken().then((idToken) => {
          dispatch({
            type: 'changeFirebaseUser',
            newFirebaseUserAndToken: {user: user, idToken: idToken}
          });
        });
      } else {
        dispatch({
          type: 'changeFirebaseUser',
          newFirebaseUserAndToken: {user: undefined, idToken: undefined}
        });        
      }
    })  
  }

  render() {
    return(<></>)
  }
}

export default AuthWrapper;
