/*------------------------------------*\
	Sandbox Entrypoint
\*------------------------------------*/
/* global __DEVTOOLS__ */
import React from 'react';
import ReactDOM from 'react-dom';

// Import Components
import App from './app.jsx';

// require('./css/modernscale.css');
// require('./css/base.css');
import 'semantic-ui-css/semantic.min.css';
import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';
import './css/custom.css';

// Hot Module Replacement HMR
// Accept the base module then re-render it if we detect a change. 
// http://gaearon.github.io/react-hot-loader/getstarted/
if(module.hot) {
  module.hot.accept('./app.jsx', function() {
    const NextApp = require('./app.jsx');
    ReactDOM.render(<NextApp/>,document.getElementById('root'));
  });
}

// Initial render
ReactDOM.render(<App/>,document.getElementById('root'));
